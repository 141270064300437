import React from "react"
import SEO from "./SEO"

const Layout = ({ children }) => {
    return (
        <>
            <SEO />
            <div className="flex flex-col min-h-screen font-sans text-gray-900 bg-ap-mid-bg">
                <header className="p-4 bg-white text-cf-header">
                    <h1 className="px-8 max-w-6xl text-3xl font-semibold mx-auto">Catherine Flores</h1>
                    <h4 className="px-8 max-w-6xl text-xl italic mx-auto">Industrial Engineer, Venezuela</h4>
                </header>
                <main className="md:text-xl">
                    {children}
                </main>

                <footer className="py-2 text-center text-gray-600 text-xs">
                    &copy; Catherine Flores 2021
                </footer>
            </div>
        </>
    )
}

export default Layout
